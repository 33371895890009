import styled from 'styled-components';

export default styled.div`
  position: relative;
  svg {
    width: .8rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .5rem;
    pointer-events: none;
  }
`;
