import { useStaticQuery, graphql } from 'gatsby';
import { useLanguage } from 'gatsby-theme-wordpress/src/context/language';

export default () => {
  const language = useLanguage();
  const { pages } = useStaticQuery(graphql`
    query useFooterContactLocation {
      pages: allWordpressPage(filter: {
        template: { eq: "templates/template-contact.php" }
        status: { eq: "publish" }
      }) {
        edges {
          node {
            wpml_current_locale
            acf {
              locations: contact_locations {
                title
                address {
                  line
                }
                phonenumbers {
                  phonenumber
                }
                fax
                email
                url
                show_in_footer
                image {
                  localFile {
                    name
                    childImageSharp {
                      fixed(width: 160, cropFocus: ENTROPY) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return pages?.edges?.find(
    ({ node }) => node.wpml_current_locale === language,
  ).node?.acf?.locations?.find(
    ({ show_in_footer }) => show_in_footer,
  );
};
