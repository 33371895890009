import React from 'react';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import { useLocation } from '../../context/location';
import { useLanguage, i18n } from '../../context/language';
import { useTranslations } from '../../context/translations';
import Select from './select';
import SelectWrapper from './select-wrapper';

const LanguageSwitch = () => {
  const location = useLocation();
  const language = useLanguage();
  const translations = useTranslations();

  const onChange = ({ target }) => {
    Cookies.set('nf_lang', target.value, { expires: 128, path: '/' });

    // homepages in translations contain the full slug, so are invalid links
    if (location.pathname === `/${language}/`) {
      // send to homepage of selected language
      return navigate(`/${target.value}/`);
    }

    const altPage = translations.find(({ locale }) => locale === target.value);
    if (altPage) {
      return navigate(altPage.link);
    }

    return navigate(`/${target.value}/`);
  };

  return (
    <SelectWrapper>
      <Select value={language} onChange={onChange}>
        {Object.entries(i18n).map(([key, lang]) => (
          <option key={key} value={key}>{lang.label}</option>
        ))}
      </Select>
      <svg
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path fill="currentColor" d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z" />
      </svg>
    </SelectWrapper>
  );
};

export default LanguageSwitch;
