import React from 'react';
import SEO from 'gatsby-theme-wordpress/src/components/seo';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Normalize } from 'styled-normalize';

import Header from './header';
import Footer from './footer';
import createTheme from './theme';

const theme = createTheme();

const Layout = ({
  children,
  page,
}) => (
  <ThemeProvider theme={theme}>
    <>
      <Normalize />
      <GlobalStyle />
      <SEO
        yoast_meta={page.yoast_meta}
        yoast_title={page.yoast_title}
        yoast_json_ld={page.yoast_json_ld}
      />
      <Header />
      <main css="background-color: white;">{children}</main>
      <Footer />
    </>
  </ThemeProvider>
);

const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${(props) => props.theme.fontSize};
  }
  body {
    background-color: ${(props) => props.theme.bodyBackgroundColor};
    font-family: ${(props) => props.theme.fontFamily};
    font-weight: ${(props) => props.theme.fontWeight};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  address {
    font-style: normal;
  }
`;

export default Layout;
