import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useI18n } from 'gatsby-theme-wordpress/src/context/language';

import Container from '../../components/container';
import SubFooter from './sub-footer';
import useContactLocation from '../../components/use-footer-contact-location';

const Footer = ({ className }) => {
  const {
    logo,
  } = useStaticQuery(graphql`
    query Footer {
      logo: file(name: { eq: "logo-lignostar" }) {
        childImageSharp {
          fluid(maxWidth: 166) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  const i18n = useI18n();
  const location = useContactLocation();

  return (
    <>
      <footer className={className}>
        <Container>
          <Wrapper>
            {logo?.childImageSharp?.fluid && (
              <div>
                <Logo
                  fluid={logo?.childImageSharp?.fluid}
                  alt="LignoStar"
                />
              </div>
            )}
            {location && (
              <div>
                <address>
                  {location.address?.map(({ line }) => (
                    <span key={`key-${line}`}>
                      {line}
                      <br />
                    </span>
                  ))}
                  <a href={location.url}>
                    {location.url.replace('https://', '')}
                  </a>
                </address>
              </div>
            )}
            {location && (
              <div>
                <table>
                  <tbody>
                    {location.phonenumbers?.map(({ phonenumber }) => (
                      <tr key={phonenumber}>
                        <td>{i18n?.abbr?.tel}:</td>
                        <td>{phonenumber}</td>
                      </tr>
                    ))}
                    {location.fax && (
                      <tr>
                        <td>{i18n?.abbr?.fax}:</td>
                        <td>{location.fax}</td>
                      </tr>
                    )}
                    {location.email && (
                      <tr>
                        <td>{i18n?.abbr?.email}:</td>
                        <td>
                          <a href={`mailto:${location.email}`}>
                            {location.email}
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </Wrapper>
        </Container>
      </footer>
      <SubFooter title={location?.title} />
    </>
  );
};

const Logo = styled(Img)`
  width: 13.33rem;
`;

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  align-items: center;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default styled(Footer)`
  padding: 2rem 0;
  background-color: ${(props) => props.theme.gray3};
  color: ${(props) => props.theme.white};
  font-size: 0.8889rem;
  line-height: 1.667rem;
  table {
    td:first-child {
      padding-right: .2rem;
    }
  }
  a {
    color: ${(props) => props.theme.white};
    text-decoration: none;
    &:hover {
      color: ${(props) => props.theme.primaryColor};
    }
  }
`;
