import styled from 'styled-components';
import Select from 'gatsby-theme-wordpress/src/components/language-switch/select';

export default styled(Select)`
  background: ${(props) => props.theme.white};
  border: ${(props) => props.theme.borderSize} solid ${(props) => props.theme.gray2};
  border-radius: ${(props) => props.theme.borderRadius};
  width: 100%;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    width: auto;
  }
`;
