import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { useLanguage } from 'gatsby-theme-wordpress/src/context/language';
import LanguageSwitch from 'gatsby-theme-wordpress/src/components/language-switch';

export const pageQuery = graphql`
  fragment MenuItems on wordpress__wp_api_menus_menus_items {
    items {
      id: wordpress_id
      url
      title
      order
      target
    }
  }
`;

const activeClassName = 'sadlfkjsdklfj';

const Nav = ({ className }) => {
  const language = useLanguage();
  const {
    menus,
  } = useStaticQuery(graphql`
    query Drawer {
      menus: allWordpressWpApiMenusMenusItems(filter: {
        slug: { eq: "main-menu" }
      }) {
        edges {
          node {
            language
            ...MenuItems
          }
        }
      }
    }
  `);
  const menu = menus.edges?.find(({ node }) => node.language === language);

  const [menuIsOpen, setMenuOpen] = useState(false);
  const toggleMenuOpen = () => setMenuOpen(!menuIsOpen);


  return (
    <>
      <Toggle type="button" onClick={toggleMenuOpen} open={menuIsOpen}>
        Menu
        <Icon open={menuIsOpen}>
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </Icon>
      </Toggle>
      <nav className={`${className} ${menuIsOpen && 'open'}`}>
        <ul>
          {menu?.node?.items?.map((item) => (
            <li key={item.id}>
              <StyledLink
                partiallyActive={item.url !== `/${language}/`}
                activeClassName={activeClassName}
                to={item.url}
              >
                {item.title}
              </StyledLink>
            </li>
          ))}
          <LanguageSwitch />
        </ul>
      </nav>
    </>
  );
};

const Toggle = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.fontColor};
  ${(props) => props.open && css`
    color: ${props.theme.primaryColor};
  `}
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: none;
  }
  &:focus {
    outline: none;
  }
`;

const Icon = styled.div`
  width: 30px;
  height: 22.5px;
  position: relative;
  margin-left: .5rem;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 50%;
    background: ${(props) => props.theme.fontColor};
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    ${(props) => props.open && css`
      background: ${props.theme.primaryColor};
    `}
  }
  span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }
  span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0 9px;
  }
  span:nth-child(1), span:nth-child(2) {
    top: 0px;
  }
  span:nth-child(3), span:nth-child(4) {
    top: 9px;
  }
  span:nth-child(5), span:nth-child(6) {
    top: 18px;
  }
  ${(props) => props.open && css`
    span:nth-child(1), span:nth-child(6) {
      transform: rotate(45deg);
    }
    span:nth-child(2), span:nth-child(5) {
      transform: rotate(-45deg);
    }
    span:nth-child(1) {
      left: 2.5px;
      top: 3.5px;
    }
    span:nth-child(2) {
      left: calc(50% - 2.5px);
      top: 3.5px;
    }
    span:nth-child(3) {
      left: -50%;
      opacity: 0;
    }
    span:nth-child(4) {
      left: 100%;
      opacity: 0;
    }
    span:nth-child(5) {
      left: 3px;
      top: 14.5px;
    }
    span:nth-child(6) {
      left: calc(50% - 2.5px);
      top: 14.5px;
    }
  `}
`;
const transition = '0.3s';
const StyledLink = styled(Link)`
  position: relative;
  display: block;
  padding: 1rem 0;
  margin-left: ${(props) => props.theme.grid.containerGap};
  margin-right: ${(props) => props.theme.grid.containerGap};
  color: ${(props) => props.theme.fontColor};
  text-decoration: none;
  transition: all 0.2s;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    border-bottom: none;
    padding: 0;
    margin-left: 0;
    margin-right: 2rem;
    font-size: 1.111rem;
    line-height: 150%;
  }
  &.${activeClassName},
  &:hover {
    &:after {
      height: 2px;
      opacity: 1;
      transform: translateY(0px);
    }
  }
  &:after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.primaryColor};
    content: '';
    opacity: 0;
    transition: height ${transition}, opacity ${transition}, transform ${transition};
    transform: translateY(-10px);
  }
`;

export default styled(Nav)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 100;
  opacity: 0;
  transition-property: all;
  transition-duration: 300ms;
  pointer-events: none;
  &.open {
    opacity: 1;
    background-color: ${(props) => props.theme.gray1};
    margin-top: ${(props) => props.borderHeight};
    pointer-events: initial;
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin-top: 0;
    opacity: 1;
    background-color: transparent;
    position: static;
    top: auto;
    left: auto;
    right: auto;
    width: auto;
    pointer-events: initial;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      display: flex;
      align-items: center;
    }
  }
`;
