// run by gatsby-config.js as well
const theme = require('gatsby-theme-nodust/src/layouts/theme');

const COLOR_PRIMARY = '#f46704';

module.exports = () => ({
  ...theme({
    COLOR_PRIMARY,
  }),
});
