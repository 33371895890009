import React from 'react';
import Helmet from 'react-helmet';
import { useLanguage } from '../context/language';

// GraphQL fields:
// yoast_meta {
//   name
//   content
//   property
// }
// yoast_title
// yoast_json_ld {
//   wordpress__context
//   wordpress__graph {
//     wordpress__type
//     wordpress__id
//     name
//     description
//     width
//     height
//     inLanguage
//     datePublished
//     dateModified
//     caption
//     isPartOf {
//       wordpress__id
//     }
//     primaryImageOfPage {
//       wordpress__id
//     }
//     url
//   }
// }

const removeNullFromObject = (obj) => Object.entries(obj)
  .reduce((list, [key, value]) => {
    if (value) {
      return ({
        ...list,
        [key]: value,
      });
    }
    return list;
  }, {});

const createGraph = (sources) => {
  const result = sources.map((obj) => {
    const cleanedObj = removeNullFromObject(obj);
    return Object.entries(cleanedObj).reduce((list, [key, value]) => {
      if (key === 'wordpress__type') {
        // eslint-disable-next-line no-param-reassign
        key = '@type';
      }
      if (key === 'wordpress__id') {
        // eslint-disable-next-line no-param-reassign
        key = '@id';
      }
      if (key === 'isPartOf') {
        // eslint-disable-next-line no-param-reassign
        value = {
          '@id': value.wordpress__id,
        };
      }
      if (key === 'primaryImageOfPage') {
        // eslint-disable-next-line no-param-reassign
        value = {
          '@id': value.wordpress__id,
        };
      }
      // if (key === 'url') {
      //   // is url an object (because featured_media), use it's original url
      //   if (value?.source_url) {
      //     // eslint-disable-next-line no-param-reassign
      //     value = value.source_url;
      //   } else {
      //     // eslint-disable-next-line no-param-reassign
      //     value = process.env.URL + value;
      //   }
      // }

      return {
        ...list,
        [key]: value,
      };
    }, {});
  });

  return JSON.stringify(result);
};

const SEO = ({
  yoast_meta = [],
  yoast_title = '',
  yoast_json_ld = [],
}) => {
  const language = useLanguage();
  return (
    <Helmet
      htmlAttributes={{
        lang: language,
        'made-by': 'Goed idee media',
      }}
      title={yoast_title}
    >
      {yoast_meta?.length > 0 && yoast_meta.map((meta, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <meta key={`${meta.name}-${index}`} {...removeNullFromObject(meta)} />
      ))}
      {yoast_json_ld?.length > 0 && yoast_json_ld.map((schema, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <script key={index} type="application/ld+json">{`
          {
            "@context": "${schema.wordpress__context}",
            "@graph": ${createGraph(schema.wordpress__graph)}
          }
        `}
        </script>
      ))}
    </Helmet>
  );
};

export default SEO;
