import React from 'react';
import styled from 'styled-components';

const Container = ({ className, children }) => (
  <div className={className}>{children}</div>
);

export default styled(Container)`
  margin: 0 auto;

  padding-left: ${(props) => props.theme.grid.containerGap};
  padding-right: ${(props) => props.theme.grid.containerGap};

  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}) {
    max-width: ${(props) => props.theme.breakingpoints.sm};
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    max-width: ${(props) => props.theme.breakingpoints.md};
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    max-width: ${(props) => props.theme.breakingpoints.lg};
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    max-width: ${(props) => (props.small ? props.theme.breakingpoints.lg : props.theme.breakingpoints.xl)}
  }
`;
