import React from 'react';
import styled from 'styled-components';

import Container from '../../components/container';


const SubFooter = ({ className, title }) => (
  <section className={className}>
    <Container>
      Copyright &copy; 2003 - {new Date().getFullYear()} All rights reserved {title}
    </Container>
  </section>
);

export default styled(SubFooter)`
  padding: 1rem 0;
  background-color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.gray2};
  font-size: 0.8889rem;
  line-height: 1.667rem;
`;
