module.exports = ({
  COLOR_PRIMARY = '#81c136',
  GRAY_3 = '#221e1f',
} = {}) => ({
  // Font
  fontSize: '18px',
  fontFamily: "'Ubuntu', sans-serif",
  fontWeight: 'normal',

  bodyBackgroundColor: 'black',
  themeColor: COLOR_PRIMARY,
  fontColor: GRAY_3,
  black: '#000000',
  gray3: GRAY_3,
  gray2: '#a3a3a3',
  gray1: '#e2e2e2',
  white: '#ffff',

  primaryColor: COLOR_PRIMARY,

  borderRadius: '0.1667rem',
  borderSize: '0.05556rem',

  sectionSpace: '3rem',
  // Grid
  grid: {
    containerGap: '1rem',
    columnGap: '1rem',
  },

  breakingpoints: {
    xs: '0',
    sm: '32rem',
    md: '42.67rem',
    lg: '55.11rem',
    xl: '66.67rem',
  },
});
