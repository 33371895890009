import React from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useLanguage } from 'gatsby-theme-wordpress/src/context/language';

import Container from '../../components/container';
import Nav from './nav';

const borderHeight = '10px';
const Header = ({ className }) => {
  const language = useLanguage();
  const {
    logo,
  } = useStaticQuery(graphql`
    query Header {
      logo: file(name: { eq: "logo" }) {
        childImageSharp {
          fluid(maxWidth: 166) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <header className={className}>
      <Container>
        <div>
          <Link to={`/${language}/`}>
            {logo?.childImageSharp?.fluid && (
              <Logo
                fluid={logo.childImageSharp.fluid}
                alt="logo"
                loading="eager"
                fadeIn={false}
              />
            )}
          </Link>
        </div>
        <div>
          <Nav borderHeight={borderHeight} />
        </div>
      </Container>
    </header>
  );
};

const Logo = styled(Img)`
  width: 7.778rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    width: 9.222rem;
  }
`;

export default styled(Header)`
  background-color: ${(props) => props.theme.white};
  position: relative;
  padding: 1rem 0;
  border-bottom: ${borderHeight} solid ${(props) => props.theme.primaryColor};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    padding: 1rem 0;
  }
  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-y: hidden;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      overflow-y: auto;
    }
  }
`;
